<template>
  <div class="index-page">
    <div class="top-main flex-center-between">
      <div class="name">{{appName || 'Etsy'}}</div>
      <div class="desc">{{i18n.t('index.title')}}</div>
    </div>


    <div class="account-box">
      <div class="header" :class="`header-${avatar}`" @click="toPath('header')"></div>
      <div class=" account">
        <div class="flex-center-between">
          <div class="flex-1">
            <div class="label flex-center-start">
              {{i18n.t('index.label1')}}($)
            </div>
            <div class="count">$ {{parseFloat(userMoney).toFixed(2)}}</div>
          </div>
          <div class="flex-1 flex-column-end">
            <Button  @click="toPath('recharge')">{{i18n.t('index.label2')}}</Button>
            <Button class="red" @click="toPath('withdraw')">{{i18n.t('index.label3')}}</Button>
          </div>
        </div>
      </div>



      <div class=" tab-list">
        <div class="tab ">
          <div class="label">{{i18n.t('index.label4')}}($)</div>
          <div class="count">{{parseFloat(commissionInfo.YesterdayCommission || 0).toFixed(2)}}</div>
        </div>
        <div class="tab ">
          <div class="label">{{i18n.t('index.label5')}}($)</div>
          <div class="count">{{parseFloat(commissionInfo.Commission || 0).toFixed(2)}}</div>
        </div>
        <div class="tab ">
          <div class="label">{{i18n.t('index.label6')}}($)</div>
          <div class="count">{{parseFloat(commissionInfo.TodayCommission || 0).toFixed(2)}}</div>
        </div>
        <div class="tab " v-if="show_freeze == 1">
          <div class="label">{{i18n.t('index.label7')}}($)</div>
          <div class="count">{{parseFloat(moneyFee).toFixed(2)}}</div>
        </div>
      </div>
    </div>

    <div class="menu-list ">
      <div class="list " :class="`bg${i + 1}`" v-for="(item, i) in menuList" :key="i" @click="toPath(item.path)">
        <span class="label">{{item.name}}</span>
        <div class="more">
          <Icon name="arrow" color="#fff" icon-size="14"></Icon>
        </div>
      </div>
    </div>

    <div class="sub-title">{{i18n.t('index.label8')}}</div>
    <div class="task-list">
      <div class="task-box flex-center-between"  @click="toPath('task')">
        <img :src="bannerUrl"  alt="">
        <div class="link">
          {{i18n.t('index.label9')}}
          <Icon name="arrow" color="#000" icon-size="16"></Icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard';
import { Button, Dialog, Popup, Icon } from 'vant'
import { userInfo } from '@/api/user'
import { getCommissionInfo } from '@/api/index'
export default {
  name: 'about',
  components: {
    Button,
    Popup,
    Icon
  },
  data() {
    return {
      activeIndex: 0,
      userMoney: '0.00',
      moneyFee: 0,
      username: "",
      commissionInfo: {},
      bannerUrl: require('../../assets/imgs/home-banner.png'),
      goodsUrl: "",
      goodsUrl2: require('../../assets/imgs/header2.png'),
      closeUrl: require('../../assets/imgs/icon-close.png'),
      copyUrl: require('../../assets/imgs/icon-copy.png'),
      linkUrl: require('../../assets/imgs/icon-link.png'),
      timer: null,
      showPopup: false,
      shareCode: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    logo() {
      return this.$store.state.logo
    },
    show_freeze() {
        return this.$store.state.show_freeze
    },
    avatar() {
      return this.$store.state.avatar
    },
    asset_positive(){
      return this.$store.state.asset_positive
    },
    shareLink() {
      return this.$store.state.appPromotion
    },
    menuList() {
      return [
        {
          icon: require('../../assets/imgs/icon-finance.png'),
          name: this.i18n.t('index.label10'),
          path: ''
        },
        {
          icon: require('../../assets/imgs/icon-notice.png'),
          name: this.i18n.t('index.label11'),
          path: 'recharge'
        },
        {
          icon: require('../../assets/imgs/icon-kefu.png'),
          name: this.i18n.t('index.label12'),
          path: 'withdraw'
        },
        {
          icon: require('../../assets/imgs/icon-share.png'),
          name: this.i18n.t('index.label13'),
          path: 'share'
        },
      ]
    }
  },
  mounted() {
    // this.shareCode = window.localStorage.getItem("shareCode")
    // this.openWindow()
    this.init()
  },
  beforeRouteLeave(to, from, next) {
    this.destroyTimer();
    next();
  },
  methods: {
    openWindow() {
      let pop_window = sessionStorage.getItem('pop_window')
      let that = this
      if(this.$store.state.pop_window === "true" && pop_window !== "true") {
        let text = this.i18n.t("index.desc1")
        let title = this.i18n.t("index.desc_title")
        let ensure = this.i18n.t("register.btn1")
        // Inshallah|Shop
        // 使用 replace() 方法来替换文本
        // appName
        title = title.replaceAll("$1", this.$store.state.appName);
        text = text.replaceAll("$1", this.$store.state.appName);
        text = text.replaceAll("$2", "\n");
        Dialog.alert({
          // title: title,
          message: text,
          messageAlign: 'left',
          confirmButtonText: ensure,
        }).then(() => {
          sessionStorage.setItem('pop_window', 'true')
          // 记录sessionStorage
          // on close
        });
      }
    },
    handleImageError() {
      this.goodsUrl = this.goodsUrl2; // 图片加载错误时，将显示指定的错误图片
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    cancel() {
      this.showPopup = false
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.init()
      }, 3000);
    },
    destroyTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    init() {
      userInfo().then(res => {
        this.$store.state.avatar = res.data.Avatar || 91
        this.userMoney = res.data.UserAccount.Balance - res.data.UserAccount.Freeze
        if(this.$store.state.asset_positive === true && this.userMoney < 0) {
          this.userMoney = res.data.UserAccount.Total
        }
        this.moneyFee =  res.data.UserAccount.Freeze
        this.username =  res.data.UserName
        // 存储用户信息
        localStorage.setItem('user', JSON.stringify(res.data))
      })

      getCommissionInfo().then(res => {
        this.commissionInfo = res.data
      })

    },
    copyData(type) {
      let data = type == 1 ? this.shareCode : this.shareLink
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.success('复制成功')
    },
  },
}
</script>
<style lang="less">

</style>
